@import '../../marketplace.css';

.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.numberOfPersons {

}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.mobileMargins {
  width: calc(100% - 48px);
  margin: 0 24px;

  @media (--viewportMedium) {
    width: 100%;
    margin: 0;
  }
}
